import type { Weekday } from '~/api/__generated-types__'

const LOCALE = 'de-DE' as const
export const TZ = 'Europe/Berlin' as const

/**
 * Returns the weekday in the local timezone (Europe/Berlin) as a number
 * (0 = Monday, 1 = Tuesday, ..., 6 = Sunday)
 * */
export const getLocalWeekday = (date: Date): Weekday => {
  const localWeekday = date.toLocaleDateString('en-US', {
    timeZone: TZ,
    weekday: 'short',
  })
  return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].indexOf(
    localWeekday,
  ) as Weekday
}

/**
 * Example output: "08:00"
 * */
export const formatTime = (dateObj: Date): string => {
  return dateObj.toLocaleTimeString(LOCALE, {
    timeZone: TZ,
    hour: '2-digit',
    minute: '2-digit',
  })
}

/**
 * Example: "8.10.2024" --> "8"
 * */
export const formatDay = (dateObj: Date): string => {
  return dateObj.toLocaleDateString(LOCALE, {
    timeZone: TZ,
    day: 'numeric',
  })
}

/**
 * Example: "8.10.2024" --> "Monday"
 * */
export const formatWeekday = (dateObj: Date): string => {
  return dateObj.toLocaleDateString(LOCALE, {
    timeZone: TZ,
    weekday: 'long',
  })
}

/**
 * Example: "8.10.2024" --> "Mo"
 * */
export const formatWeekdayShort = (dateObj: Date): string => {
  return dateObj.toLocaleDateString(LOCALE, {
    timeZone: TZ,
    weekday: 'short',
  })
}

export const formatDayWithWeekday = (dateObj: Date): string => {
  return dateObj.toLocaleDateString(LOCALE, {
    timeZone: TZ,
    day: 'numeric',
    weekday: 'long',
  })
}

/**
 * Example: "2024-04-29 07:30:00" --> "Monday, 29. Apr."
 * */
export const formatWeekdayWithDateShort = (dateObj: Date): string => {
  return dateObj.toLocaleDateString(LOCALE, {
    timeZone: TZ,
    weekday: 'long',
    day: 'numeric',
    month: 'short',
  })
}

/**
 * Example: "2024-04-29 07:30:00" --> "Monday, 29. April"
 * */
export const formatWeekdayWithDateLong = (dateObj: Date): string => {
  return dateObj.toLocaleDateString(LOCALE, {
    timeZone: TZ,
    day: 'numeric',
    month: 'long',
    weekday: 'long',
  })
}

/**
 * Find the nearest Monday (in local tz) in the past OR the same date if the input date is a Monday.
 * Returns a date object at Monday within the same time and timezone as the input date.
 * */
export const findLatestMonday = (date: Date): Date => {
  const weekday = getLocalWeekday(date)
  const daysToMonday = weekday === 0 ? 0 : weekday
  const monday = new Date(date)
  monday.setDate(date.getDate() - daysToMonday)
  return monday
}

/**
 * Example: "2024-05-08T00:00:00+02:00" --> "08-05-2024"
 * */
export const toLocaleIsoDate = (date: Date): string => {
  return date
    .toLocaleDateString('en-US', {
      timeZone: TZ,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    .replace(/\//g, '-')
}
/**
 * Example: "2024-05-08T00:00:00+02:00" --> "08.05.2024"
 * */
export const toGermanDateFormat = (date: Date): string => {
  return date.toLocaleDateString('de-DE', {
    timeZone: TZ,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
}

/**
 * Returns true if two date objects have the same date (ignoring time).
 * We use formatNumericDate() not date.date() because we need to compare the local date
 * */
export const isSameDate = (date1: Date, date2: Date): boolean => {
  return toLocaleIsoDate(date1) === toLocaleIsoDate(date2)
}

/**
 * Returns a new Date object with the given number of days added.
 * */
export const addDays = (date: Date, numberOfDays: number): Date => {
  const newDate = new Date(date)
  newDate.setDate(date.getDate() + numberOfDays)
  return newDate
}

/**
 * Returns the difference between two Dates in minutes.
 * */
export const minutesDiff = (start: Date, end: Date): number => {
  let differenceValue = (start.getTime() - end.getTime()) / 1000
  differenceValue /= 60
  return Math.abs(Math.round(differenceValue))
}

/**
 * Returns a boolean if a date is between start and end date
 * */
export function isBetween(from: Date, to: Date, check: Date) {
  if (check >= from && check <= to) {
    return true
  }
  return false
}

/**
 * Returns a boolean if a date is before end date
 * */
export function isBefore(end: Date, check: Date) {
  return check <= end
}

/**
 * Returns a boolean if a day is before or equal to check day
 * */
export function isBeforeOrEqualDay({ end, check }: { end: Date; check: Date }) {
  const checkDay = new Date(
    check.getFullYear(),
    check.getMonth(),
    check.getDate(),
  )
  const endDay = new Date(end.getFullYear(), end.getMonth(), end.getDate())

  return checkDay.getTime() <= endDay.getTime()
}
